import './bootstrap';

$(function () {
  const article_view = localStorage.getItem('article:view');
  if (article_view && article_view == 'list') {
    $('.toggle-item').toggleClass('active');
    $('#article-view').toggleClass('grid-view list-view');
  } else {
  }

  // csrf-protect all ajax calls
  $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } });

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });

  // confirm dialog
  jconfirm.defaults = {
    theme: 'bootstrap',
    useBootstrap: false,
    container: 'body',
    animation: 'none',
    boxWidth: '300px',
    draggable: true,

    //backgroundDismiss: true,
  }

  // var url = new URL(document.location);
  // var params = url.searchParams;
  // var paper = params.get("field");
  // console.log(paper);
  // $("#field_art").val(paper);

  $('.toggle-item').on('click', function (e) {
    $('.toggle-item').toggleClass('active');
    const view = $(this).data('view');
    localStorage.setItem('article:view', view);
    $('#article-view').toggleClass('list-view grid-view');
  });

  $('.btn-confirm').on('click', function (e) {
    e.preventDefault();
    var confirmtitle = $(this).attr('data-confirmtitle');
    var confirmform = $(this).attr('data-confirmform');
    var confirmtext = $(this).attr('data-confirmtext');
    var confirmbutton = $(this).attr('data-confirmbutton') || 'btn btn-sm btn-danger';
    var confirmbuttontext = $(this).attr('data-confirmbuttontext') || 'Annehmen';
    $.confirm({
      title: confirmtitle,
      content: confirmtext,
      buttons: {
        cancel: {
          text: 'Abbrechen',
          btnClass: 'btn btn-sm btn-secondary',
          keys: ['esc'],
          action: function () { } // close
        },
        confirm: {
          text: confirmbuttontext,
          btnClass: confirmbutton,
          keys: ['enter'],
          action: function (btn) {
            $('#' + confirmform).submit();
          }
        },
      }
    });
  });

  $(".sidebar-dropdown > a").click(function () {
    $(".sidebar-submenu").slideUp(200);
    if (
      $(this)
        .parent()
        .hasClass("active")
    ) {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .parent()
        .removeClass("active");
    } else {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .next(".sidebar-submenu")
        .slideDown(200);
      $(this)
        .parent()
        .addClass("active");
    }
  });

  $("#close-sidebar").click(function () {
    $(".page-wrapper").removeClass("toggled");
  });
  $("#show-sidebar").click(function () {
    $(".page-wrapper").addClass("toggled");
  });

  $('#name').on('change', function () {
    let self = $(this);
    let str = self.val();

    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    $('#slug').val(str);
  });

  $('#title').on('change', function () {
    let self = $(this);
    let str = self.val();

    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    $('#slug').val(str);
  });

  $('#nav-bars').on('click', function() {
    let device_width = $(window).width();
    let bars = $('#nav-bars');
    let side = $('#sidenav');
    let side_width = side.width();
    if (side_width == 55) {
      bars.addClass('change');
      side.width('300px');
      $(".nav-title").removeClass('title-none');
      if(device_width <= 767) {
        side.css({position:'absolute', 'z-index': 99});
      }
    } else {
      $(".nav-title").addClass('title-none');
      bars.removeClass('change');
      side.width('55px');
      if(device_width <= 767) {
        side.css({position:'relative'});
      }
    }
  })

  $('.user-back').on('click', function() {
    let device_width = $(window).width();
    let bars = $('#nav-bars');
    let side = $('#sidenav');
    let side_width = side.width();
    if (side_width == 55) {
      bars.addClass('change');
      side.width('300px');
      $(".nav-title").removeClass('title-none');
      if(device_width <= 767) {
        side.css({position:'absolute', 'z-index': 99});
      }
    } else {
      $(".nav-title").addClass('title-none');
      bars.removeClass('change');
      side.width('55px');
      if(device_width <= 767) {
        side.css({position:'relative'});
      }
    }
  })
});